body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-dark .nav .nav-item .nav-link {
  color: #fff;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

div > .container-fluid {
  padding-top: 50px;
  padding-right: 50px;
  padding-left: 50px;
}

.clickable-column:hover {
  cursor: pointer;
}

#pulse-check-queue-table {
  table-layout: auto;
}

.fa:hover {
  cursor: pointer;
}

.fa-check-circle:hover {
  color: #8aa354;
}

.fa-minus-circle:hover {
  color: #e0a800;
}

.fa-exclamation-circle:hover {
  color: #a30000;
}